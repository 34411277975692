<template>

  <DefaultCrudView
    @cdp-settings="toCdpSettings"
    :breadcrumbsItems="breadcrumbs"
    :actions="actions"
  >
    <template slot="content">

      <v-data-table
        :headers="headers"
        :items="profileViews"
        class="elevation-1"
        :loading="loading"
        :options.sync="options"
        :server-items-length="itemsLength"
        :footer-props="$defaultDataTableFooterProps"
      >
        <template v-slot:item.customer_properties="{ item }">
          <span
            color="primary"
            dark
          >
            {{ getCustomerPropertiesTitles(item.customer_properties) }}
          </span>
        </template>
      
        <template v-slot:item.actions="{ item }">

          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                @click="exportProfileViewModal(item)"
              >
                mdi-export
              </v-icon>
            </template>
            <span>{{ $t('export') }}</span>
          </v-tooltip>

        </template>

      </v-data-table>

      <DefaultModal 
        :title="$t('export_profile_view')"
        ref="modal"
        submit-button-text="download"
        @submit="download"
      >
        <template v-slot:content>
          <DynamicFormContent 
            :fields="fields"
          ></DynamicFormContent>
        </template>
      </DefaultModal>

    </template>
  
  </DefaultCrudView>
</template>

<style scoped>
  .container {
    padding: 0;
  }

  .table-container {
    margin-top: 20px;
  }
</style>

<script>

import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'

import DefaultCrudView from '@/components/DefaultCrudView.vue'
import DefaultModal from '@/components/DefaultModal.vue'
import DynamicFormContent from '@/components/DynamicFormContent.vue'

import CustomerPropertyActions from '@/store/cdp/customer_property/actions-types'
import ProfileViewActions from '@/store/cdp/profile_view/actions-types'
import ProfileViewMutations from '@/store/cdp/profile_view/mutations-types'
import AppActions from '@/store/app/actions-types'
import router from "@/router";

export default {
  name: "ProfileViewExport",

  components: {
    DefaultCrudView,
    DefaultModal,
    DynamicFormContent
  },

  computed: {
    ...mapGetters('cdp/customer_property', [
      'customerPropertyTitleById'
    ]),

    ...mapState({
      fields: state => state.cdp.profile_view.exportFields,
      profileViews: state => state.cdp.profile_view.profileViews,
      selectedProfileView: state => state.cdp.profile_view.selectedProfileView,
    }),

    breadcrumbs() {
      return [
        {
          text: this.$t('profile_view_export'),
          prependIcon: 'mdi-clipboard-account-outline',
        },
      ]
    }
  },

  data() {
    return {
      loading: true,
      options: {},
      itemsLength: 0,
      headers: [
        {text: 'Title', value: 'profile_view_title'},
        {text: 'Customer Properties', value: 'customer_properties'},
        {text: 'Actions', value: 'actions', sortable: false},
      ],
      actions: [
        {
          text: "cdp_settings",
          eventToEmit: "cdp-settings",
        }
      ],
    }
  },

  mounted() {
    this.loading = true
    this.[ProfileViewMutations.SET_SELECTED_PROFILE_VIEW](null)

    this.[CustomerPropertyActions.GET_ALL]()
      .then(() => {
        this.reloadData()
      })
  },

  watch: {
    options: {
      handler () {
        this.reloadData()
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions('app', [
      AppActions.OPEN_APP_SUCCESS_MESSAGE
    ]),

    ...mapActions('cdp/customer_property', [
      CustomerPropertyActions.GET_ALL,
    ]),

    ...mapActions('cdp/profile_view', [
      ProfileViewActions.GET_ALL,
      ProfileViewActions.CREATE_PROFILE_VIEW_EXPORT,
    ]),

    ...mapMutations('cdp/profile_view', [
      ProfileViewMutations.SET_SELECTED_PROFILE_VIEW,
    ]),

    reloadData() {
      let options = {...this.$route.query}

      if (this.options.sortBy.length > 0) {
        options.order = this.options.sortBy[0]
      }

      if (this.options.sortDesc.length > 0 && this.options.sortDesc[0]) {
        options.direction = 'DESC'
      }

      options.limit = this.options.itemsPerPage
      options.offset = this.options.itemsPerPage * (this.options.page - 1)

      if (!this.inactiveFilterValue) {
        options = {
          ...options,
          'filter[active]' : "true"
        }
      }

      this.loading = true

      this.[ProfileViewActions.GET_ALL](options)
        .then((result) => {
          this.itemsLength = result.total

          this.loading = false
        })
        .catch(() => this.loading = false)
    },

    toCdpSettings() {
      router.push({
        name: 'CdpSettings',
        params: { appId: this.$route.params.appId }
      })
    },

    getCustomerPropertiesTitles(customerProperties) {
      let titles = []

      customerProperties.forEach((item) => titles.push(item.customer_property_title))

      return titles.join(', ')
    },

    exportProfileViewModal(profileView) {
      this.[ProfileViewMutations.SET_SELECTED_PROFILE_VIEW](profileView)

      this.$refs.modal.openModal()
    },

    download() {
      this.[ProfileViewActions.CREATE_PROFILE_VIEW_EXPORT]()
        .then((response) => {
          const label = this.selectedProfileView.profile_view_title

          const blob = new Blob([response], { type: 'text/csv' })
          const link = document.createElement('a')

          link.href = URL.createObjectURL(blob)
          link.download = `${label}-export.csv`
          link.click()

          URL.revokeObjectURL(link.href)

          this.$refs.modal.closeModal()
        })
        .catch(() => this.$refs.modal.submitting = false)
    },
  }
}

</script>